






























import { Component, Prop, Vue } from "vue-property-decorator";
import { IconsType, User } from "@/types";
import {
  mdiAccountOutline,
  mdiBriefcaseVariantOutline,
  mdiCalendarOutline,
  mdiCurrencyRub,
  mdiEmailOutline
} from "@mdi/js";
import { namespace } from "vuex-class";
import NumberFormat = Intl.NumberFormat;
const AppModule = namespace("AppModule");
@Component({
  components: {
    IconSubtitle: () => import("@/components/IconSubtitle.vue")
  }
})
export default class CardEmployee extends Vue {
  @AppModule.State
  public currencyFormatter!: NumberFormat;
  @Prop() readonly user!: User;
  get date() {
    return !this.user.dateWorkBegin
      ? null
      : new Date(this.user.dateWorkBegin).toLocaleString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
  }
  get experience() {
    if (this.date && this.user.workLength)
      return `${this.date} (${this.user.workLength})`;
    if (this.date && !this.user.workLength) return this.date;
    if (this.date && !this.user.workLength) return this.user.workLength;
    return null;
  }
  get name() {
    let result = "";
    if (this.user.familyName) result = this.user.familyName + " ";
    if (this.user.firstName) result += this.user.firstName;
    return result === "" ? this.user.tabel : result;
  }
  get points() {
    return this.currencyFormatter.format(this.user.points);
  }
  get pointsString() {
    return this.points.substr(0, this.points.length - 5);
  }
  private icons: IconsType = {
    account: mdiAccountOutline,
    briefcase: mdiBriefcaseVariantOutline,
    rub: mdiCurrencyRub,
    email: mdiEmailOutline,
    calendar: mdiCalendarOutline
  };
}
